.App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .image-item {
    margin: 10px 10px;
    border: 1px solid #00000024;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    background: white;
  }
  .image-item__btn-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
  button {
    margin-bottom: 5px !important;
  }
  #canvas {
    display: none;
  }
  .original {
    display: none;
  }
  #uploadContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
  }
  .thumbnail {
      margin-top: auto;
  }
  .MuiButton-root {
      margin-bottom: 5px;
  }
  .parent {
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  html, body, #app, #app>div {
    height: 100%
  }
  .Topbar {
    background-color: #333;
  }
  .Topbar-logo {
    width: 50px;
    padding: 7px 0 9px;
    margin-left: 30px;
    order: 1;
    flex: 0 1 auto;
  }
  .Topbar-container {
    padding: 0 20px;
    display: flex;
    max-height: 38px;
  }
  .Topbar-nav {
    flex: 1 1 auto;
    text-align: left;
    overflow: hidden;
  }
  .Topbar-links {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;
  }
  .Nav-item {
    padding: 5px 0 5px 0;
    box-sizing: content-box;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    line-height: 22px;
  }
  .Topbar-nav ul li a {
    display: block;
    padding: 3px 0;
    text-decoration: none;
    white-space: nowrap;
    color: #dcdcdc;
  }
  .Bottombar {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-grow: 1;
    background-color: #333;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 100px;
    align-content: stretch;
    margin-top: 130px;
    right: 0;
  }
  .Bottombar-nav ul li a {
    display: block;
    padding: 3px 0;
    text-decoration: none;
    white-space: nowrap;
    color: #dcdcdc;

  }

  .Bottombar-container {
    padding: 0 20px;
    display: flex;
    max-height: 38px;
  }
  .Bottombar-nav {
    flex: 1 1 auto;
    overflow: hidden;
  }
  .Bottombar-links {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;
  }
  .hidden {
    display: none !important;
  }
  .uploadBox {
    width: 100%;
    border: 1.5px solid rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: center;
    border-radius: 5px;
    height: 25%;
    align-items: center;
    flex-direction: column;
    margin: 0px !important;
    background: white;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    padding-top: 20px;
    padding-bottom: 20px;

  }
  .uploadText {
    font-size: 14px;
  }
  .mainButton {
    margin-right: 5px !important;
  }
  .mainButtonContainer {
    margin-top: 12px;
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .mainButton {
    flex-grow: .10;
  }
  .uploadInfo {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: flex-end;
  }
  .upload__image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-bottom: 25px;
  }